var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import { MethodCode, ModelCode } from '../@types/Permission';
import PermissionGuard from '../auth/PermissionsGuard';
import { Calendar, Call, KpiEdit, KpiNew, Kpis, KpiView, LoginPage, MyTodoList, UserList, Page404, PermissionGroup, StatClientResponseEdit, StatClientResponseList, StatClientResponseNew, StatClientResponseView, StatsClient, StatsClientEdit, StatsClientNew, StatsClientShow, UserEdit, UserView, Analytics, Notifications, Offices, NotesList, NoteNew, NoteEdit, NoteDetails, ExpensesList, ExpenseNew, ExpenseEdit, ExpenseDetails, CodeList, CodeEdit, CodeView, TodoView, CashRegisterList, CashRegisterNew, CashRegisterEdit, CashRegisterView, OfficesEdit, FileManagerList, SessionList, SessionCalendar, Page403, BounusAmountList, BonusAmountNew, BonusAmountView, BonusAmountEdit, ReceiptPaymentList, ReceiptPaymentNew, ReceiptPaymentFill, ReceiptPaymentView, ReceiptPaymentEdit, PaymentNoteList, } from './elements';
import PaymentNoteNew from '../pages/Configuration/PaymentNotes/PaymentNotesNew';
import PaymentNotesEdit from '../pages/Configuration/PaymentNotes/PaymentNotesEdit';
// ----------------------------------------------------------------------
export default function Router() {
    return useRoutes([
        {
            path: '/',
            children: [
                { element: _jsx(Navigate, { to: "/login", replace: true }), index: true },
                {
                    path: 'login',
                    element: (_jsx(GuestGuard, { children: _jsx(LoginPage, {}) })),
                },
            ],
        },
        {
            path: '/dashboard',
            element: (_jsx(AuthGuard, { children: _jsx(DashboardLayout, {}) })),
            children: [
                { element: _jsx(Navigate, { to: PATH_AFTER_LOGIN, replace: true }), index: true },
                {
                    path: 'operators',
                    element: _jsx(Outlet, {}),
                    children: [
                        {
                            path: '',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.USER, method: MethodCode.LIST }, { children: _jsx(UserList, {}) }))),
                        },
                        {
                            path: 'view/:id',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.USER, method: MethodCode.VIEW }, { children: _jsx(UserView, {}) }))),
                        },
                        {
                            path: 'edit/:id',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.USER, method: MethodCode.EDIT }, { children: _jsx(UserEdit, {}) }))),
                        },
                    ],
                },
                {
                    path: 'kpis',
                    element: _jsx(Outlet, {}),
                    children: [
                        {
                            path: '',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.KPI, method: MethodCode.LIST }, { children: _jsx(Kpis, {}) }))),
                        },
                        {
                            path: 'new',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.KPI, method: MethodCode.CREATE }, { children: _jsx(KpiNew, {}) }))),
                        },
                        {
                            path: 'edit/:id',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.KPI, method: MethodCode.EDIT }, { children: _jsx(KpiEdit, {}) }))),
                        },
                        {
                            path: 'view/:id',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.KPI, method: MethodCode.VIEW }, { children: _jsx(KpiView, {}) }))),
                        },
                    ],
                },
                {
                    path: 'stat-client-response',
                    element: _jsx(Outlet, {}),
                    children: [
                        {
                            path: '',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.STAT_CLIENT_RESPONSE, method: MethodCode.LIST }, { children: _jsx(StatClientResponseList, {}) }))),
                        },
                        {
                            path: 'new/:statClientId',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.STAT_CLIENT_RESPONSE, method: MethodCode.CREATE }, { children: _jsx(StatClientResponseNew, {}) }))),
                        },
                        {
                            path: 'edit/:statClientRestId',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.STAT_CLIENT_RESPONSE, method: MethodCode.EDIT }, { children: _jsx(StatClientResponseEdit, {}) }))),
                        },
                        {
                            path: 'view/:statClientRestId',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.STAT_CLIENT_RESPONSE, method: MethodCode.VIEW }, { children: _jsx(StatClientResponseView, {}) }))),
                        },
                    ],
                },
                {
                    path: 'permissions',
                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.PERMISSION_GROUP, method: MethodCode.LIST }, { children: _jsx(PermissionGroup, {}) }))),
                },
                {
                    path: 'calendar',
                    element: _jsx(Calendar, {}),
                },
                {
                    path: 'calls',
                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.CALLS, method: MethodCode.LIST }, { children: _jsx(Call, {}) }))),
                },
                {
                    path: 'stats-client',
                    element: _jsx(Outlet, {}),
                    children: [
                        {
                            path: '',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.STAT_CLIENT, method: MethodCode.LIST }, { children: _jsx(StatsClient, {}) }))),
                        },
                        {
                            path: 'new',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.STAT_CLIENT, method: MethodCode.CREATE }, { children: _jsx(StatsClientNew, {}) }))),
                        },
                        {
                            path: 'view/:id',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.STAT_CLIENT, method: MethodCode.VIEW }, { children: _jsx(StatsClientShow, {}) }))),
                        },
                        {
                            path: 'edit/:id',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.STAT_CLIENT, method: MethodCode.EDIT }, { children: _jsx(StatsClientEdit, {}) }))),
                        },
                    ],
                },
                {
                    path: 'todo-list',
                    element: _jsx(Outlet, {}),
                    children: [
                        {
                            path: '',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.TODO, method: MethodCode.LIST }, { children: _jsx(MyTodoList, {}) }))),
                        },
                        {
                            path: 'view/:id',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.TODO, method: MethodCode.VIEW }, { children: _jsx(TodoView, {}) }))),
                        },
                    ],
                },
                {
                    path: 'session',
                    element: _jsx(Outlet, {}),
                    children: [
                        {
                            path: '',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.SESSION, method: MethodCode.LIST }, { children: _jsx(SessionList, {}) }))),
                        },
                        {
                            path: 'user/:id/calendar',
                            element: (_jsxs(PermissionGuard, __assign({ model: ModelCode.SESSION, method: MethodCode.LIST }, { children: [_jsx(SessionCalendar, {}), ","] }))),
                        },
                    ],
                },
                {
                    path: 'analytics',
                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.ANALYTICS, method: MethodCode.LIST, onePermission: [
                            [ModelCode.ANALYTICS, MethodCode.LIST],
                            [ModelCode.BALANCE_ANALYTICS, MethodCode.VIEW],
                        ] }, { children: _jsx(Analytics, {}) }))),
                },
                {
                    path: 'notifications',
                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.NOTIFICATION, method: MethodCode.LIST }, { children: _jsx(Notifications, {}) }))),
                },
                {
                    path: 'offices',
                    element: _jsx(Outlet, {}),
                    children: [
                        {
                            path: '',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.OFFICE, method: MethodCode.LIST }, { children: _jsx(Offices, {}) }))),
                        },
                        {
                            path: 'edit/:id',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.OFFICE, method: MethodCode.EDIT }, { children: _jsx(OfficesEdit, {}) }))),
                        },
                    ],
                },
                {
                    path: 'notes',
                    element: _jsx(Outlet, {}),
                    children: [
                        {
                            path: '',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.NOTE, method: MethodCode.LIST }, { children: _jsx(NotesList, {}) }))),
                        },
                        {
                            path: 'new',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.NOTE, method: MethodCode.CREATE }, { children: _jsx(NoteNew, {}) }))),
                        },
                        {
                            path: 'edit/:id',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.NOTE, method: MethodCode.EDIT }, { children: _jsx(NoteEdit, {}) }))),
                        },
                        {
                            path: 'view/:id',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.NOTE, method: MethodCode.VIEW }, { children: _jsx(NoteDetails, {}) }))),
                        },
                    ],
                },
                {
                    path: 'finances',
                    element: _jsx(Outlet, {}),
                    children: [
                        {
                            path: 'expenses',
                            element: _jsx(Outlet, {}),
                            children: [
                                {
                                    path: '',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.OPERATION, method: MethodCode.LIST }, { children: _jsx(ExpensesList, {}) }))),
                                },
                                {
                                    path: 'new',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.OPERATION, method: MethodCode.CREATE }, { children: _jsx(ExpenseNew, {}) }))),
                                },
                                {
                                    path: 'edit/:id',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.OPERATION, method: MethodCode.EDIT }, { children: _jsx(ExpenseEdit, {}) }))),
                                },
                                {
                                    path: 'view/:id',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.OPERATION, method: MethodCode.VIEW }, { children: _jsx(ExpenseDetails, {}) }))),
                                },
                            ],
                        },
                        {
                            path: 'codes',
                            element: _jsx(Outlet, {}),
                            children: [
                                {
                                    path: '',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.CODE, method: MethodCode.LIST }, { children: _jsx(CodeList, {}) }))),
                                },
                                {
                                    path: 'edit/:id',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.CODE, method: MethodCode.EDIT }, { children: _jsx(CodeEdit, {}) }))),
                                },
                                {
                                    path: 'view/:id',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.CODE, method: MethodCode.VIEW }, { children: _jsx(CodeView, {}) }))),
                                },
                            ],
                        },
                        {
                            path: 'general-cash-register',
                            element: _jsx(Outlet, {}),
                            children: [
                                {
                                    path: '',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.CASH_DESK_PAYMENT, method: MethodCode.LIST }, { children: _jsx(CashRegisterList, {}) }))),
                                },
                                {
                                    path: 'new',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.CASH_DESK_PAYMENT, method: MethodCode.CREATE }, { children: _jsx(CashRegisterNew, {}) }))),
                                },
                                {
                                    path: 'edit/:id',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.CASH_DESK_PAYMENT, method: MethodCode.EDIT }, { children: _jsx(CashRegisterEdit, {}) }))),
                                },
                                {
                                    path: 'view/:id',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.CASH_DESK_PAYMENT, method: MethodCode.VIEW }, { children: _jsx(CashRegisterView, {}) }))),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'folder',
                    element: _jsx(Outlet, {}),
                    children: [
                        {
                            path: '',
                            element: (_jsx(PermissionGuard, __assign({ model: ModelCode.FOLDER, method: MethodCode.LIST }, { children: _jsx(FileManagerList, {}) }))),
                        },
                    ],
                },
                {
                    path: 'configuration',
                    element: _jsx(Outlet, {}),
                    children: [
                        {
                            path: 'bonus-amount',
                            element: _jsx(Outlet, {}),
                            children: [
                                {
                                    path: '',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.CONFIG_BONUS_AMOUNT, method: MethodCode.LIST }, { children: _jsx(BounusAmountList, {}) }))),
                                },
                                {
                                    path: 'new',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.CONFIG_BONUS_AMOUNT, method: MethodCode.CREATE }, { children: _jsx(BonusAmountNew, {}) }))),
                                },
                                {
                                    path: 'view/:id',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.CONFIG_BONUS_AMOUNT, method: MethodCode.VIEW }, { children: _jsx(BonusAmountView, {}) }))),
                                },
                                {
                                    path: 'edit/:id',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.CONFIG_BONUS_AMOUNT, method: MethodCode.EDIT }, { children: _jsx(BonusAmountEdit, {}) }))),
                                },
                            ],
                        },
                        {
                            path: 'receipt-payment',
                            element: _jsx(Outlet, {}),
                            children: [
                                {
                                    path: '',
                                    element: _jsx(ReceiptPaymentList, {}),
                                },
                                {
                                    path: 'new',
                                    element: _jsx(ReceiptPaymentNew, {}),
                                },
                                {
                                    path: 'fill',
                                    element: _jsx(ReceiptPaymentFill, {}),
                                },
                                {
                                    path: 'view/:id',
                                    element: _jsx(ReceiptPaymentView, {}),
                                },
                                {
                                    path: 'edit/:id',
                                    element: _jsx(ReceiptPaymentEdit, {}),
                                },
                            ],
                        },
                        {
                            path: 'payment-notes',
                            element: _jsx(Outlet, {}),
                            children: [
                                {
                                    path: '',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.PAYMENT_NOTE, method: MethodCode.LIST }, { children: _jsx(PaymentNoteList, {}) }))),
                                },
                                {
                                    path: 'new',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.PAYMENT_NOTE, method: MethodCode.CREATE }, { children: _jsx(PaymentNoteNew, {}) }))),
                                },
                                // {
                                //   path: 'view/:id',
                                //   element: (
                                //     <PermissionGuard model={ModelCode.CONFIG_BONUS_AMOUNT} method={MethodCode.VIEW}>
                                //       <BonusAmountView />
                                //     </PermissionGuard>
                                //   ),
                                // },
                                {
                                    path: 'edit/:id',
                                    element: (_jsx(PermissionGuard, __assign({ model: ModelCode.PAYMENT_NOTE, method: MethodCode.EDIT }, { children: _jsx(PaymentNotesEdit, {}) }))),
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            element: _jsx(CompactLayout, {}),
            children: [
                { path: '403', element: _jsx(Page403, {}) },
                { path: '404', element: _jsx(Page404, {}) },
            ],
        },
        { path: '*', element: _jsx(Navigate, { to: "/404", replace: true }) },
    ]);
}
